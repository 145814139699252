import { ParamsModel } from '@src/app/models/params-model';

// Below are two functions which remove the sort key if they match. This prevents
// the api call from failing due to the tab
export function filterParamsModelFromEventsToAsset(
  params: Partial<ParamsModel>
): Partial<ParamsModel> {
  const filteredParams: Partial<ParamsModel> = {};

  for (const key in params) {
    if (params.hasOwnProperty(key)) {
      if (
        key === 'sort' &&
        (params[key].includes('updatedAt') ||
          params[key].includes('createdAt') ||
          params[key].includes('eventStatus'))
      ) {
        continue;
      }
      filteredParams[key] = params[key];
    }
  }

  return filteredParams;
}

export function filterParamsModelFromAssetToEvents(
  params: Partial<ParamsModel>
): Partial<ParamsModel> {
  const filteredParams: Partial<ParamsModel> = {};

  for (const key in params) {
    if (params.hasOwnProperty(key)) {
      if (
        key === 'sort' &&
        (params[key].includes('assetStatus') ||
          params[key].includes('lastScanStatus') ||
          params[key].includes('lastScanDate') ||
          params[key].includes('nextDeadline'))
      ) {
        continue;
      }
      filteredParams[key] = params[key];
    }
  }

  return filteredParams;
}
