import {
  ChangeDetectionStrategy,
  Component,
  OnDestroy,
  OnInit
} from '@angular/core';

import { AuthService } from '@auth0/auth0-angular';
import { debounceTime, filter, map, take, takeUntil } from 'rxjs/operators';

import { UserCompany } from '@app/models/user.company.model';
import {
  SidenavFooterConfig,
  SidenavGlobalConfig,
  SidenavHeaderConfig
} from '@config/sidenav.config';
import { appName } from '@environments/shared';
import { DataDogService } from './services/data-dog/data-dog.service';
import { DeviceDetectionService } from './services/device-detection/device-detection.service';
import { SelectedCompanyService } from './services/selected-company/selected-company.service';
import { PermissionsService } from '@zonar-ui/auth';
import { Subject } from 'rxjs';
import { PendoService } from '@zonar-ui/analytics';
import { GTCxMobileHelperService } from '@zonar-ui/gtcx-mobile-helper';
import { FeatureToggleService } from '@zonar-ui/feature-toggle';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AppComponent implements OnInit, OnDestroy {
  loading = true;
  title = appName;
  sidenavConfig = SidenavGlobalConfig;
  sidenavHeader = SidenavHeaderConfig;
  sidenavFooter = SidenavFooterConfig;
  onDestroy$ = new Subject<void>();

  constructor(
    private auth: AuthService,
    private datadog: DataDogService,
    private selectedCompanyService: SelectedCompanyService,
    private permissionsService: PermissionsService,
    private pendoService: PendoService,
    public device: DeviceDetectionService,
    private featureToggleService: FeatureToggleService,
    private gtcxMobileHelperService: GTCxMobileHelperService
  ) {
    this.auth.isAuthenticated$
      .pipe(
        takeUntil(this.onDestroy$),
        filter((isAuthenticated) => Boolean(isAuthenticated))
      )
      .subscribe(() => {
        this.datadog.startSessionReplayRecording();
      });
  }

  ngOnInit() {
    this.permissionsService
      .getPermissions()
      .pipe(
        takeUntil(this.onDestroy$),
        debounceTime(250),
        filter((permissions) => Boolean(permissions))
      )
      .subscribe(() => {
        this.pendoService.initialize();
      });
  }

  ngOnDestroy() {
    this.onDestroy$.next();
    this.onDestroy$.complete();
    this.datadog.stopSessionReplayRecording();
  }

  switchToSelectedCompany(incomingCompany: UserCompany): void {
    // This is needed until fix is applied to sidenav component.
    this.permissionsService
      .getCompanyMap()
      .pipe(
        take(1),
        map((companies) => {
          if (companies && companies[incomingCompany.value]) {
            // Setting Company context to use it on Zonar-Owner-Id for selecting right profile.
            this.permissionsService.setCurrentCompanyContext(
              companies[incomingCompany.value]
            );
          }
          this.selectedCompanyService.setCompanyIdFromSideNav(incomingCompany);
          this.featureToggleService.initializeDevCycle(incomingCompany.value);
          this.gtcxMobileHelperService.setCurrentCompany(incomingCompany.value);
        })
      )
      .subscribe();
  }

  onSideNavMobileMenuButtonToggled(event): void {
    this.sidenavConfig.mobileOpened = event;
  }
}
