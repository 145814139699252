import { Component, Input } from '@angular/core';
import { TranslateService } from '@zonar-ui/i18n';
import { Translations } from '@services/i18n/translations.service';

@Component({
  selector: 'app-import-guide',
  templateUrl: './import-guide.component.html',
  styleUrls: ['./import-guide.component.scss']
})
export class ImportGuideComponent {
  @Input() title = '';
  @Input() urlDownload = '';
  @Input() guideDescription: any = null;
  @Input() guideList = [];
  constructor(
    public translateService: TranslateService,
    public translations: Translations
  ) {}
}
