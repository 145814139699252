import { Component, Inject, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatListModule } from '@angular/material/list';
import { Translations } from '@services/i18n/translations.service';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { TranslateModule } from '@zonar-ui/i18n';
import { MatCardModule } from '@angular/material/card';

@Component({
  selector: 'app-scan-result',
  standalone: true,
  imports: [CommonModule, MatListModule, TranslateModule, MatCardModule],
  templateUrl: './scan-result.component.html',
  styleUrls: ['./scan-result.component.scss']
})
export class ScanResultComponent implements OnInit {
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<ScanResultComponent>,
    public translations: Translations
  ) {}

  ngOnInit(): void {
    console.log(this.data);
  }

  onCancel(): void {
    this.dialogRef.close();
  }
}
