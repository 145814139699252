import { environment } from '@src/environments/environment';
import { SidenavParams } from '@zonar-ui/sidenav/lib/sidenav/interfaces';

export const SidenavHeaderConfig = {
  displayName: true, // if we want to display name of the user
  avatarImageUrl: null, // if we want to show a specific avatar picture e.g. user's avatar
  optionalSubtitle: null
};

export const SidenavFooterConfig = {
  items: []
};

export const SidenavGlobalConfig: SidenavParams = {
  mobileOpened: true,
  expanded: true,
  footerOffset: false
};
