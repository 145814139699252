<div *ngIf="file; else fileDropTmpl" class="file-info">
  <div>{{ translations.uploadDialog.confirmMsg | translate }}</div>
  <br />
  <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="20px">
    <div fxFlex="5">
      <mat-icon>upload_file</mat-icon>
    </div>
    <div fxFlex="95">
      <div fxLayout="row" fxLayoutAlign="space-between">
        <span fxFlex="75" class="file-name"><i>{{file.name}}</i></span>
      </div>
    </div>
  </div>
</div>
<ng-template #fileDropTmpl>
  <div
    cdkDropList
    (cdkDropListDropped)="onFileDropped($event.target.file[0])"
    cdkDragDisabled
    fxLayoutAlign="center"
    class="file-upload-container"
    fxLayout="column"
    appDragAndDrop
    (fileDropped)="onFileDropped($event)">
    <mat-icon
      fxFlex="10"
      fxLayoutAlign="end center"
      class="mat-icon material-icons mat-icon-no-color file-img"
      fxFlex="50">
      backup
    </mat-icon>
    <input
      class="input-upload"
      type="file"
      id="fileDropRef"
      [multiple]="false"
      (change)="onFileDropped($event.target.files[0])"
      accept=".csv" />
    <label fxFlex="50">
      {{ translations.manageAssets.dragAndDropDesStart | translate }}
      <span class="browse-text" (click)="onBrowse()"
        >{{ translations.manageAssets.browser | translate }}</span
      >
      {{ translations.manageAssets.dragAndDropDesEnd | translate }}
    </label>
  </div>
</ng-template>
