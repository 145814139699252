import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { AssetsDataStoreModule } from '@app/data-stores/assets/assets-data-store.module';
/* data stores */
// import necessary data stores here

@NgModule({
  declarations: [],
  imports: [CommonModule, AssetsDataStoreModule],
  exports: [StoreModule, EffectsModule]
})
export class DataStoresModule {}
