import {
  ChangeDetectionStrategy,
  Component,
  OnDestroy,
  OnInit
} from '@angular/core';
import { CommonModule } from '@angular/common';
import {
  ActivatedRoute,
  Router,
  Routes,
  RouterModule,
  NavigationEnd
} from '@angular/router';
import { MatCardModule } from '@angular/material/card';
import { MatTabsModule } from '@angular/material/tabs';
import { CoreModule, FlexLayoutModule } from '@angular/flex-layout';

import { Observable, Subject } from 'rxjs';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';

// zonar-ui pattern library
import { TranslateModule, TranslateService } from '@zonar-ui/i18n';
import { PermissionsService } from '@zonar-ui/auth';

import { Translations } from '@app/services/i18n/translations.service';

// child components
import { PageTitleComponent } from '@app/components/page-title/page-title.component';
import { AssetContainerComponent } from '@app/containers/asset-container/asset-container.component';
import { filter, startWith, takeUntil, tap } from 'rxjs/operators';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { DialogService } from '@src/app/services/dialog-service/dialog.service';
import { BulkImportSize } from '@src/app/shared/consts/common.const';

@Component({
  selector: 'app-overview-container',
  standalone: true,
  imports: [
    FlexLayoutModule,
    CoreModule,
    CommonModule,
    RouterModule,
    MatCardModule,
    MatTabsModule,
    MatIconModule,
    MatButtonModule,
    NgxSkeletonLoaderModule,
    PageTitleComponent,
    TranslateModule,
    AssetContainerComponent
  ],
  templateUrl: './overview-container.component.html',
  styleUrls: ['./overview-container.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class OverviewContainerComponent implements OnInit, OnDestroy {
  public cardData;
  public columns;
  public filterList;
  public pageTitle: string;
  public hasRequestManageAssetsPermission$: Observable<boolean>;

  navLinks = [];
  activeLink = null;

  private onDestroy$ = new Subject<void>();
  constructor(
    private route: ActivatedRoute,
    private router: Router,
    public translateService: TranslateService,
    public translations: Translations,
    private dialogService: DialogService,
    private permissionsService: PermissionsService
  ) {
    this.hasRequestManageAssetsPermission$ =
      this.permissionsService.hasPermission('manage:assets');
  }

  ngOnInit(): void {
    this.navLinks =
      !!this.route.routeConfig && !!this.route.routeConfig.children.length
        ? this.buildNavigatingItems(this.route.routeConfig.children)
        : [];
    this.activeLink = this.navLinks[0];
    this.subscribeForRouteChanges();
  }

  buildNavigatingItems(routes: Routes) {
    return routes
      .filter((route) => route.data)
      .map(({ path = '', data }) => ({
        path: path,
        label: data.label
      }));
  }

  subscribeForRouteChanges() {
    this.router.events
      .pipe(
        takeUntil(this.onDestroy$),
        filter((event) => event instanceof NavigationEnd),
        startWith(this.router), // let the component load the initial state from the injected Route, so that the event
        tap((event: NavigationEnd) => {
          let currentTabRoute = event.url.split('/').at(-1);
          this.pageTitle = event.url.split('/').at(-2);
          this.activeLink = this.navLinks.find((link) =>
            currentTabRoute.startsWith(link.path)
          );
        })
      )
      .subscribe();
  }

  ngOnDestroy(): void {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }

  importCsv() {
    this.dialogService
      .showBulkImportDialog(
        {
          fieldsCheckDup: { vin: 'vin' }
        },
        BulkImportSize.maxWidth,
        BulkImportSize.height
      )
      .subscribe((reloadRequire) => {
        if (reloadRequire) {
          //this.dataSource$.loadData(this.paramsTable);
        }
      });
  }
}
