import { Injectable } from '@angular/core';
import { IAsset, Status, AssetViewStatusEnum } from '@models/asset-model';
import { get, cloneDeep } from 'lodash';
import { Observable } from 'rxjs';
import { HttpClient, HttpParams } from '@angular/common/http';
import { ValidationService } from '@services/validation.service';
import { environment as env } from '@environments/environment';
import { EmissionsCheckApiService } from '@services/emissions-check-api/emissions-check-api.service';

@Injectable({
  providedIn: 'root'
})
export class BulkAssetImportService {
  private ASSET_RESOURCE = 'assets';
  errorMessagesTranslated: any = {};
  assetMapping: any = {};
  bulkImportTranslated: any = {};
  registrationExpirationDateColumn = 'Registration Expiration Date';
  requiredColumns: string[] = ['VIN', 'Registered Country', 'Registered State'];
  constructor(
    private http: HttpClient,
    private validationService: ValidationService,
    private emissionsCheckApiService: EmissionsCheckApiService
  ) {}
  /*fetchingFieldOptions(data) {
    return forkJoin([
      this.coreAssetApiService.getTypeAssets(),
      this.coreAssetApiService.getFuelTypes(),
      this.assetLogicService.getCVIConfigurationProfile(data.companyId)
    ]).pipe(map(prePopulateDataList => {
      const assetType = prePopulateDataList[0].body;
      const assetSubType = {};
      Object.keys(assetType).forEach(key => {
        assetSubType[key] = new Map(assetType[key].map(type => {
          return [this.getTranslatedAssetEnumValue(type, EnumTranslateCategory.ASSET_SUBTYPE, key)?.toLowerCase(), type];
        }));
      });
      return {
        countryList: new Map(Object.values(countryTimezone.getAllCountries() || {}).map(country => {
          return [this.getTranslatedAssetEnumValue(country.name,
            EnumTranslateCategory.COUNTRY_OF_REGISTRATION)?.toLowerCase(), country.name];
        })),
        fuelType: new Map(prePopulateDataList[1].body.map(fuel => {
          return [this.getTranslatedAssetEnumValue(fuel, EnumTranslateCategory.ASSET_FUELTYPE)?.toLowerCase(), fuel];
        })),
        assetType: new Map(Object.keys(assetType).map(key => {
          return [this.getTranslatedAssetEnumValue(key, EnumTranslateCategory.ASSET_TYPE)?.toLowerCase(), key];
        })),
        assetSubType,
        evirConfProf: new Map(prePopulateDataList[2].map(evirconf => {
          return [evirconf?.toLowerCase(), evirconf];
        })),
        classList: new Map(AssetClass.map(assetClass => {
          return [this.getTranslatedAssetEnumValue(assetClass, EnumTranslateCategory.ASSET_CLASS)?.toLowerCase(), assetClass];
        }))
      };
    }));
  }*/
  parseRecord(recordDataObject, params): IAsset {
    return {
      vin: recordDataObject['vin'],
      registeredState: recordDataObject['registeredState'],
      registeredCountry: recordDataObject['registeredCountry'],
      registrationExpirationDate:
        recordDataObject['registrationExpirationDate'],
      status: AssetViewStatusEnum.WAITING
    };
  }

  getFilePathDownloadAsset() {
    const downloadPath = `assets/bulk-import-sample/example.csv`;
    return downloadPath;
  }

  getData(
    url: string,
    params?: any,
    responseType: any = { observe: 'response' }
  ): Observable<any> {
    if (params) {
      const httpParams: HttpParams = new HttpParams({
        fromObject: params as any
      });
      const options = Object.assign({ params: httpParams }, responseType);
      return this.http.get(url, options);
    }
    return this.http.get(url, responseType);
  }

  deDupRecord(inputRecords, fieldsCheckDup) {
    const tmpInputRecords = cloneDeep(inputRecords);
    const mappingFields = {};
    Object.keys(fieldsCheckDup).forEach((key) => {
      mappingFields[this.assetMapping[key]] = fieldsCheckDup[key];
    });
    tmpInputRecords?.forEach((record, index) => {
      this.checkRecordByFields(
        record,
        tmpInputRecords,
        index + 1,
        mappingFields
      );
    });
    return tmpInputRecords;
  }

  checkRecordByFields(recordSource, recordTargets, startIndex, mappingFields) {
    recordTargets.forEach((record, index) => {
      if (index < startIndex) {
        return;
      }

      Object.keys(mappingFields).forEach((key) => {
        const value = get(recordSource, mappingFields[key]);
        if (value !== null && value === get(record, mappingFields[key])) {
          if (!record.hasOwnProperty('fieldDup')) {
            record.fieldDup = [];
          }
          if (!record.fieldDup.includes(key)) {
            record.fieldDup.push(key);
          }
        }
      });
    });
  }

  validateVin(value): boolean {
    this.validationService.setCurrentValidationField(this.requiredColumns[0]);
    return (
      this.validationService.validateRequiredField(value) &&
      this.validationService.validateMinLength(value, 17) &&
      this.validationService.validateMaxLength(value, 17) //&&
      //this.validationService.validateFormatPattern(value, /[A-HJ-NPR-Z0-9]{17}/)
    );
  }

  validateRegisteredState(value: string): boolean {
    this.validationService.setCurrentValidationField(this.requiredColumns[2]);
    return (
      this.validationService.validateRequiredField(value) &&
      this.validationService.validateMinLength(value, 2) &&
      this.validationService.validateMaxLength(value, 2) &&
      this.validationService.validateFormatPattern(value, /^[A-Za-z]+$/)
    );
  }

  validateRegisteredCountry(value: string): boolean {
    this.validationService.setCurrentValidationField(this.requiredColumns[1]);
    return (
      this.validationService.validateRequiredField(value) &&
      this.validationService.validateMinLength(value, 3) &&
      this.validationService.validateMaxLength(value, 3) &&
      this.validationService.validateFormatPattern(value, /^[A-Za-z]+$/)
    );
  }

  validateComplianceDate(value: string): boolean {
    this.validationService.setCurrentValidationField(
      this.registrationExpirationDateColumn
    );
    return value
      ? this.validationService.validateMinLength(value, 10) &&
          this.validationService.validateFormatPattern(
            value,
            /^\d{4}-\d{2}-\d{2}$/
          ) &&
          this.isValidDate(value)
      : true;
  }

  isValidDate(dateString: string): boolean {
    const parsedDate = new Date(dateString);
    // Check if the date is invalid
    if (isNaN(parsedDate.getTime())) {
      return false;
    }
    // Additional checks can be added here if needed
    return true;
  }

  validateRecord(record: IAsset, params) {
    const tmpRecord = cloneDeep(record);
    this.validationService.setRecordDataValidate(tmpRecord);

    return {
      isValid:
        this.validateVin(record.vin) &&
        this.validateRegisteredState(record.registeredState) &&
        this.validateRegisteredCountry(record.registeredCountry) &&
        this.validateComplianceDate(record.nextDeadLine),
      fieldName: this.validationService.getCurrentValidationField(),
      data: this.validationService.getRecordDataValidate()
        ? this.validationService.getRecordDataValidate()
        : record
    };
  }

  getMisMatchColumnAmount(row, headerArray) {
    return row ? headerArray.length - row.length : null;
  }

  createRecord(asset: IAsset) {
    return this.emissionsCheckApiService.createAsset(asset);
  }

  getAssets(companyId: string, params) {
    return this.emissionsCheckApiService.getAssets(params);
  }

  isTextUndefined(text: string | undefined | null): boolean {
    return text === undefined || text === null || text === 'undefined';
  }
  toCamelCaseString(text: string): string {
    if (this.isTextUndefined(text)) {
      return null;
    }
    const words = text.split(' ');

    // Transform the first word to lowercase
    words[0] = words[0].toLowerCase();

    // Capitalize the first letter of the remaining words
    for (let i = 1; i < words.length; i++) {
      words[i] =
        words[i].charAt(0).toUpperCase() + words[i].slice(1).toLowerCase();
    }

    return words.join('');
  }

  toCamelCaseArray(textArray: string[]): string[] {
    return textArray.map((text) => this.toCamelCaseString(text));
  }

  csvContainsRequiredColumns(csvHeader: string[]) {
    const camelCaseList = this.toCamelCaseArray(csvHeader);
    const camelCaseRequiredFields = this.toCamelCaseArray(this.requiredColumns);

    return camelCaseRequiredFields.every((requiredField) =>
      camelCaseList.includes(requiredField)
    );
  }

  toTitleCase(str: string): string {
    return str
      ?.split(' ')
      .map((strParts) => {
        if (strParts !== '') {
          return (
            strParts[0].toUpperCase() + strParts.substring(1).toLowerCase()
          );
        }
        return '';
      })
      .join(' ');
  }
}
