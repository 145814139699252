import { CommonModule, formatDate } from '@angular/common';
import { HttpErrorResponse, HttpResponse } from '@angular/common/http';
import {
  ChangeDetectionStrategy,
  Component,
  Inject,
  OnDestroy,
  OnInit
} from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { Router } from '@angular/router';
import { SharedModule } from '@modules/shared/shared.module';
import { Store } from '@ngrx/store';
import { BulkAssetImportService } from '@services/bulk-asset-import/bulk-asset-import.service';
import { Translations } from '@services/i18n/translations.service';
import { TranslateModule, TranslateService } from '@zonar-ui/i18n';
import { ZonarUiNotificationsService } from '@zonar-ui/notifications';
import { saveAs } from 'file-saver';
import { BehaviorSubject, EMPTY, Observable, Subject } from 'rxjs';
import { catchError, finalize, map, take, takeUntil } from 'rxjs/operators';
import { EmissionsCheckApiService } from '../../services/emissions-check-api/emissions-check-api.service';

@Component({
  selector: 'app-bulk-asset-import',
  standalone: true,
  imports: [
    CommonModule,
    TranslateModule,
    FlexLayoutModule,
    MatIconModule,
    MatProgressSpinnerModule,
    MatButtonModule,
    SharedModule
  ],
  templateUrl: './bulk-asset-import.component.html',
  styleUrls: ['./bulk-asset-import.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class BulkAssetImportComponent implements OnInit, OnDestroy {
  public translated;
  public file: any;

  public uploadInProgress$!: Observable<boolean>;

  private onDestroy$ = new Subject<void>();
  private duration = 7;
  private durationForError = 60 * 60; // setting higher duration, so that user can take action on download csv.
  private notificationSnackbarBtnClass = 'notification-btn';
  private notificationMessage: any = {};

  private _uploadInProgressSub = new BehaviorSubject<boolean>(false);
  private errorCsvTextToDownloadCsv!: any;
  private totalRecords!: number;
  private successCount!: number;
  private failureCount!: number;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public translateService: TranslateService,
    public translations: Translations,
    public bulkAssetImportService: BulkAssetImportService,
    public dialogRef: MatDialogRef<BulkAssetImportComponent>,
    public zonarUiNotificationsService: ZonarUiNotificationsService,
    private emissionsCheckApiService: EmissionsCheckApiService,
    private router: Router,
    public store: Store<any>
  ) {
    this.translateService
      .get([
        this.translations.uploadDialog.textOkButton,
        this.translations.uploadDialog.textUploadButton,
        this.translations.uploadDialog.textCancelButton,
        this.translations.supportModule.importGuide.asset.fields.vinFieldName,
        ...Object.keys(this.translations.manageAssets).map(
          (k) => this.translations.manageAssets[k]
        )
      ])
      .pipe(take(1))
      .subscribe((_translated) => (this.translated = _translated));
    this.uploadInProgress$ = this._uploadInProgressSub.asObservable();
  }

  ngOnDestroy(): void {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }

  ngOnInit(): void {
    this.zonarUiNotificationsService.dismiss();
    this.initNotificationMessage();
  }

  onCancel(): void {
    this.dialogRef.close();
  }

  private reloadDataTable(): void {
    this.router.navigate(['/emissions/asset'], {
      queryParams: {
        _nonce: new Date().getTime() // This is to refresh the table component
      },
      queryParamsHandling: 'merge'
    });
  }

  fileUploadEmit(file) {
    this.file = file;
  }

  getFilePathDownload() {
    return this.bulkAssetImportService.getFilePathDownloadAsset();
  }

  onUpload() {
    this._uploadInProgressSub.next(true);
    this.emissionsCheckApiService
      .importAssets(this.file)
      .pipe(
        catchError((err) => {
          this.processImportAssetsError(err);
          return EMPTY;
        }),
        map((res: HttpResponse<any>) => {
          this.totalRecords = +res.headers.get('CSV-Total-Records');
          this.onOpenSuccessNotification();
          // Closing dialog before the reload data, to fix dialog close when router.navigate is called
          this.dialogRef.close(this.file);
          this.reloadDataTable();
        }),
        finalize(() => {
          this.dialogRef.close(this.file);
          this._uploadInProgressSub.next(false);
        })
      )
      .subscribe();
  }

  private processImportAssetsError(err: HttpErrorResponse) {
    console.log(err);
    if (err.status === 400 || err.status === 406) {
      // Partial download
      this.errorCsvTextToDownloadCsv = err.error;
      this.totalRecords = +err.headers.get('CSV-Total-Records');
      this.failureCount = +err.headers.get('CSV-Failed-Records');
      this.successCount = this.totalRecords - this.failureCount;
      if (err.error === 400) {
        this.onOpenWarningsNotification();
        this.dialogRef.close(this.file);
        this.reloadDataTable();
      } else {
        this.onOpenErrorNotification();
      }
    } else {
      // Unknown errors
      this.zonarUiNotificationsService.openError(
        '',
        this.notificationMessage.unknownError,
        5
      );
    }
  }

  initNotificationMessage() {
    const notificationMessage = 'notificationMessage.asset.bulkImport';
    const assetHeaderRow = 'bulkImport.assetHeaderRow';

    this.translateService
      .get([notificationMessage, assetHeaderRow])
      .pipe(takeUntil(this.onDestroy$))
      .subscribe((translatedText) => {
        this.bulkAssetImportService.bulkImportTranslated = translatedText;

        this.notificationMessage = translatedText[notificationMessage];
        this.bulkAssetImportService.errorMessagesTranslated =
          translatedText[notificationMessage];
      });
  }

  private onOpenSuccessNotification() {
    this.zonarUiNotificationsService.openSuccess(
      '',
      this.notificationMessage.successMessage.replace(
        '{{count}}',
        this.totalRecords
      ),
      this.duration
    );
  }

  private onOpenWarningsNotification() {
    this.zonarUiNotificationsService.openWarning(
      '',
      this.notificationMessage.warningMessage
        .replace('{{count}}', this.totalRecords)
        .replace('{{partialCount}}', this.successCount),
      this.durationForError,
      {
        buttonText: this.notificationMessage.downloadCsvBtnTxt,
        onButtonClick: () => this.writeCSVFile()
      },
      this.notificationSnackbarBtnClass
    );
  }

  private onOpenErrorNotification() {
    this.zonarUiNotificationsService.openError(
      '',
      this.notificationMessage.errorMessage.replace(
        '{{count}}',
        this.failureCount
      ),
      this.durationForError,
      {
        buttonText: this.notificationMessage.downloadCsvBtnTxt,
        onButtonClick: () => this.writeCSVFile()
      },
      this.notificationSnackbarBtnClass
    );
  }

  private writeCSVFile() {
    if (this.errorCsvTextToDownloadCsv) {
      this.zonarUiNotificationsService.dismiss();
      saveAs(
        new Blob([this.errorCsvTextToDownloadCsv]),
        `error_records_${formatDate(
          new Date(),
          'yyyy-MM-dd-hh-mm-a',
          'en'
        ).toString()}.csv`
      );
    }
  }
}
