import { IAsset } from '@models/asset-model';

export interface BulkAssetsCSVPayload {
  csvFile?: any;
  companyId?: string;
  divisions?: any[];
  fieldsCheckDup?: any;
  params?: any;
}

export enum BulkAssetsImportStatuses {
  VALIDATING_FILE = 'validating_file',
  PARSING_DATA = 'parsing_data',
  INVALID_FILE = 'invalid_file',
  LOADING_DATA = 'loading_data',
  DATA_LOADED = 'data_loaded',
  IMPORTING_ASSETS = 'importing_assets',
  IMPORTING_COMPLETED = 'importing_completed'
}

export interface BulkAssetsImportCompletedPayload {
  data?: any;
}

export interface RowError {
  index?: number;
  message?: string;
}

export interface ImportAssetSuccessfulPayload {
  asset?: Partial<IAsset>[];
  assetId?: string;
}

export interface ImportAssetFailurePayload {
  erroredAsset: Partial<IAsset>;
  error: any;
}

export interface ImportBulkAssets {
  /* please update the status from the  */
  status?: string;
  uploadedData?: {
    csvFile: any;
    headerArray: string[];
    recordHashmap?: any[];
    recordRows: IAsset[];
  };
  importedData?: {
    processedRowsCount: number;
    successfullyImportedAssets?: any[];
    /* total rows that will be imported */
    totalRowsCount: number;
    erroredAssets?: IAsset[];
  };
}
export interface BulkAssetsCSVRecordParsed {
  resultRecords?: any[];
  headers?: string[];
  recordHashmap?: any;
  params?: any;
  recordRows?: any;
}

export interface BulkAssetsValidateDuplicate {
  recordParsed: IAsset[];
  fieldsCheckDup: any;
}

export interface BulkAssetDataUpload {
  record?: any;
  index?: number;
  headerArray?: string[];
  prePopulateData?: any;
  companyId?: string;
  assetParams?: IAsset;
}

export interface UpdateAssetsStatus {
  successfullyUpdateStatusAssetIds?: string[];
  failureUpdateStatusAssets?: ErrorEditStatusAsset[];
  processedAssetsCount: number;
}

export interface ErrorEditStatusAsset {
  assetId: string;
  errorMessage: string;
}

export interface UpdateAssetStatusPayload {
  assetId: string;
  assetStatus: boolean;
}

export interface UpdateAssetStatusSuccessfulPayload {
  assetId: string;
}

export interface UpdateAssetStatusFailurePayload {
  assetId: string;
  errorMessage: string;
}
