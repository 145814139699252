<div
  class="requirement-field-container"
  fxLayout="column"
  fxLayoutAlign="start">
  <span class="title">{{title}}</span>
  <span class="guide-sub-description" *ngIf="guideDescription">
    {{translateService.get(translations.supportModule.importGuide.asset.guideDescription.subDescPrefix)
    | async | translate }}
    <a [href]="urlDownload" download
      >{{translateService.get(translations.supportModule.importGuide.here) |
      async | translate}}</a
    >
    {{translateService.get(translations.supportModule.importGuide.asset.guideDescription.subDescSuffix)
    | async | translate }}
  </span>
  <div class="fields-container" fxLayout="column" fxLayoutAlign="start">
    <div
      class="field-header"
      fxLayout="row"
      fxLayoutAlign="center center"
      fxLayoutGap="12px">
      <span fxFlex="30"
        >{{translateService.get(translations.supportModule.importGuide.field) |
        async | translate }}</span
      >
      <span fxFlex="70"
        >{{translateService.get(translations.supportModule.importGuide.requirements)
        | async | translate }}</span
      >
    </div>
    <div
      class="field-body"
      *ngFor="let guide of guideList"
      fxLayout="row"
      fxLayoutAlign="center center"
      fxLayoutGap="12px">
      <span fxFlex="30" [ngClass]="guide.required ? 'required' : ''"
        >{{guide.fieldName}}</span
      >
      <span fxFlex="70">{{guide.requirement}}</span>
    </div>
  </div>
</div>
