import { Injectable } from '@angular/core';

import { User } from '@auth0/auth0-spa-js';
import { datadogRum } from '@datadog/browser-rum';
import { datadogLogs } from '@datadog/browser-logs';
import { first } from 'rxjs/operators';

import { PermissionsService } from '@zonar-ui/auth';

import { environment } from '@environments/environment';
import { appName, appVersion } from 'src/environments/shared';

@Injectable({
  providedIn: 'root'
})
//  TODO: temporary comment out
export class DataDogService {
  private user: User;
  constructor(private permissions: PermissionsService) {
    if (environment.name !== 'local') {
      datadogRum.init({
        applicationId: environment.datadog.applicationId,
        clientToken: environment.datadog.clientToken,
        site: environment.datadog.site,
        env: environment.datadog.tagEnvironment,
        service: appName,
        version: appVersion,
        sampleRate: 100,
        sessionSampleRate: 100,
        premiumSampleRate: 100,
        replaySampleRate: 100,
        trackUserInteractions: true,
        trackInteractions: true,
        useSecureSessionCookie: true,
        useCrossSiteSessionCookie: true,
        allowedTracingOrigins: [
          /https:\/\/.*\.zonarsystems\.net/,
          environment.apiBase.url
        ],
        defaultPrivacyLevel: 'mask-user-input'
      });

      datadogLogs.init({
        clientToken: environment.datadog.clientToken,
        env: environment.datadog.tagEnvironment,
        service: appName,
        site: environment.datadog.site,
        forwardErrorsToLogs: false,
        sampleRate: 100
      });

      this.permissions.getUser().subscribe((user) => {
        if (user) {
          this.user = user;
          datadogRum.setUser({
            id: this.user.id,
            email: this.user.email,
            sub: this.user.sub
          });
        }
      });
    }
  }

  startSessionReplayRecording(): void {
    datadogRum.startSessionReplayRecording();
  }

  stopSessionReplayRecording(): void {
    datadogRum.stopSessionReplayRecording();
  }
}
