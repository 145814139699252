<div class="scan-result" fxLayout="column" fxLayoutAlign="space-between start">
  <mat-list>
    <mat-list-item *ngFor="let d of data.errors; index as idx; count as len;">
      <!--span matListItemTitle>{{d.vin}}</span>-->
      <span matListItemLine>{{d.error}}</span>
    </mat-list-item>
  </mat-list>
  <mat-card *ngIf="data && data.errors?.length === 0">
    <mat-card-content
      >{{translations.overview.scan.successMessage |
      translate}}</mat-card-content
    >
  </mat-card>
  <div
    class="button-container"
    fxFill
    fxLayout="row"
    fxLayoutAlign="end end"
    fxFlex="30">
    <button mat-stroked-button type="button" (click)="onCancel()">
      {{ translations.uploadDialog.textCancelButton | translate }}
    </button>
  </div>
</div>
