import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { WarningComponent } from '@components/warning/warning.component';
import { FileUploadComponent } from '@components/file-upload/file-upload.component';
import { BulkAssetImportRequirementsComponent } from '@components/bulk-asset-import-requirements/bulk-asset-import-requirements.component';
import { ImportGuideComponent } from '@components/import-guide/import-guide.component';
import { FlexLayoutModule, FlexModule } from '@angular/flex-layout';
import { FormsModule } from '@angular/forms';
import { MatIconModule } from '@angular/material/icon';
import { MatSliderModule } from '@angular/material/slider';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { DragAndDropDirective } from '@app/directive/drag-and-drop/drag-and-drop.directive';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
  declarations: [
    WarningComponent,
    FileUploadComponent,
    ImportGuideComponent,
    BulkAssetImportRequirementsComponent,
    DragAndDropDirective
  ],
  imports: [
    CommonModule,
    TranslateModule,
    FlexModule,
    FormsModule,
    MatIconModule,
    MatSliderModule,
    DragDropModule
  ],
  exports: [
    WarningComponent,
    FileUploadComponent,
    ImportGuideComponent,
    BulkAssetImportRequirementsComponent,
    DragAndDropDirective
  ]
})
export class SharedModule {}
