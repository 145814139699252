export enum urlPath {
  ASSET = 'asset',
  EVENT = 'event'
}

export interface UrlParams {
  assetId: Array<string>;
  status: Array<string>;
  startTime: string;
  endTime: string;
  companyId: string;
  eventType: string;
}

export const BulkImportSize = {
  maxWidth: '55rem',
  height: 'auto'
};
