import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  EventEmitter,
  OnInit,
  Output
} from '@angular/core';

import { TranslateService } from '@zonar-ui/i18n';
import { Translations } from '@services/i18n/translations.service';

@Component({
  selector: 'app-file-upload',
  templateUrl: './file-upload.component.html',
  styleUrls: ['./file-upload.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FileUploadComponent {
  @Output() fileUploadEmit = new EventEmitter<any>();

  public file: any;

  constructor(
    private el: ElementRef,
    public translateService: TranslateService,
    public translations: Translations
  ) {}

  onBrowse() {
    this.el.nativeElement.querySelector('#fileDropRef').click();
  }

  onFileDropped(file) {
    if (this.isValidFormatFile(file)) {
      this.file = file;
      this.fileUploadEmit.emit(file);
    }
  }

  isValidFormatFile(file) {
    return file.name.endsWith('.csv');
  }
}
