/* angular */
import { createAction, props } from '@ngrx/store';
import {
  BulkAssetDataUpload,
  BulkAssetsCSVPayload,
  BulkAssetsCSVRecordParsed,
  BulkAssetsImportCompletedPayload,
  BulkAssetsValidateDuplicate,
  ImportAssetFailurePayload,
  ImportAssetSuccessfulPayload,
  RowError,
  UpdateAssetStatusFailurePayload,
  UpdateAssetStatusPayload,
  UpdateAssetStatusSuccessfulPayload
} from './assets-data-store.interface';
import { IAsset } from '@models/asset-model';

/* action types */
export enum ActionTypes {
  /* Bulk Asset Import CSV Action Types */
  BulkAssetsCSVDataFileSelected = '[ASSETS] BulkAssetsCSVDataFileSelected',
  BulkAssetsCSVParsingStarted = '[ASSETS] BulkAssetsCSVParsingStarted',
  BulkAssetsCSVRecordParsed = '[ASSETS] BulkAssetsCSVRecordParsed',
  BulkAssetsValidateDuplicate = '[ASSETS] BulkAssetsValidateDuplicate',
  BulkAssetsCSVDataValidated = '[ASSETS] BulkAssetsCSVDataValidated',
  BulkAssetCSVDataUpload = '[ASSETS] BulkAssetCSVDataUpload',
  SaveError = '[ASSETS] SaveError',
  ImportAsset = '[ASSETS] ImportAsset',
  ImportAssetSuccessful = '[ASSETS] ImportAssetSuccessful',
  BulkAssetsImportCompleted = '[ASSETS] BulkAssetsImportCompleted',
  ResetAssetState = '[ASSETS] ResetAssetState',
  ResetImportAssets = '[ASSETS] ResetImportAssets',
  BulkAssetCSVFileForInvalidDataGenerated = '[ASSETS] BulkAssetCSVFileForInvalidDataGenerated',
  ImportAssetFailure = '[ASSETS] ImportAssetFailure',
  /* Bulk Asset Activate/Deactivate Action Types */
  BulkAssetStatusSelected = '[ASSETS] BulkAssetStatusSelected',
  UpdateAssetStatus = '[ASSETS] UpdateAssetStatus',
  UpdateAssetStatusSuccessful = '[ASSETS] UpdateAssetStatusSuccessful',
  UpdateAssetStatusFailure = '[ASSETS] UpdateAssetStatusFailure',
  BulkAssetStatusReset = '[ASSETS] BulkAssetStatusReset',

  GetAssets = '[ASSETS] GetAssets',
  GetAssetsSuccessfully = '[ASSETS] GetAssetsSuccessfully',
  GetAssetsFailure = '[ASSETS] GetAssetsFailure',
  SetCombineAssetList = '[ASSETS] SetCombineAssetList',
  GetTypeAssets = '[ASSETS] GetTypeAssets',
  GetTypeAssetsSuccessfully = '[ASSETS] GetTypeAssetsSuccessfully',
  GetTypeAssetsFailure = '[ASSETS] GetTypeAssetsFailure'
}

//#region Bulk Asset Import CSV File And Data Validation Actions
export const bulkAssetsCSVDataFileSelected = createAction(
  ActionTypes.BulkAssetsCSVDataFileSelected,
  props<{ payload: Partial<BulkAssetsCSVPayload> }>()
);

export const bulkAssetsCSVParsingStarted = createAction(
  ActionTypes.BulkAssetsCSVParsingStarted,
  props<{ payload: BulkAssetsCSVPayload }>()
);

export const bulkAssetsCSVRecordParsed = createAction(
  ActionTypes.BulkAssetsCSVRecordParsed,
  props<{ payload: BulkAssetsCSVRecordParsed }>()
);

export const bulkAssetsValidateDuplicate = createAction(
  ActionTypes.BulkAssetsValidateDuplicate,
  props<{ payload: BulkAssetsValidateDuplicate }>()
);

export const bulkAssetsCSVDataValidated = createAction(
  ActionTypes.BulkAssetsCSVDataValidated,
  props<{ payload: BulkAssetsCSVRecordParsed }>()
);

export const bulkAssetCSVDataUpload = createAction(
  ActionTypes.BulkAssetCSVDataUpload,
  props<{ payload: BulkAssetDataUpload }>()
);

export const saveError = createAction(
  ActionTypes.SaveError,
  props<{ payload: RowError }>()
);

export const importAsset = createAction(
  ActionTypes.ImportAsset,
  props<{ payload: BulkAssetDataUpload }>()
);

export const importAssetSuccessful = createAction(
  ActionTypes.ImportAssetSuccessful,
  props<{ payload: ImportAssetSuccessfulPayload }>()
);

export const bulkAssetsImportCompleted = createAction(
  ActionTypes.BulkAssetsImportCompleted,
  props<{ payload: BulkAssetsImportCompletedPayload }>()
);

export const bulkAssetCSVFileForInvalidDataGenerated = createAction(
  ActionTypes.BulkAssetCSVFileForInvalidDataGenerated,
  props<{ payload: BulkAssetsCSVPayload }>()
);

export const importAssetFailure = createAction(
  ActionTypes.ImportAssetFailure,
  props<{ payload: ImportAssetFailurePayload }>()
);

export const resetAssetState = createAction(ActionTypes.ResetAssetState);
export const resetImportAssets = createAction(ActionTypes.ResetImportAssets);

//#region Bulk Asset Activate/Deactivate Actions
export const bulkAssetStatusSelected = createAction(
  ActionTypes.BulkAssetStatusSelected
);
export const updateAssetStatus = createAction(
  ActionTypes.UpdateAssetStatus,
  props<{ payload: UpdateAssetStatusPayload }>()
);
export const updateAssetStatusSuccessful = createAction(
  ActionTypes.UpdateAssetStatusSuccessful,
  props<{ payload: UpdateAssetStatusSuccessfulPayload }>()
);
export const updateAssetStatusFailure = createAction(
  ActionTypes.UpdateAssetStatusFailure,
  props<{ payload: UpdateAssetStatusFailurePayload }>()
);
export const bulkAssetStatusReset = createAction(
  ActionTypes.BulkAssetStatusReset
);

export const getAssets = createAction(
  ActionTypes.GetAssets,
  props<{
    payload: {
      companyId: string;
      params: any;
    };
  }>()
);
export const getAssetsSuccessfully = createAction(
  ActionTypes.GetAssetsSuccessfully,
  props<{ payload: { assetList: IAsset[] } }>()
);
export const getAssetsFailure = createAction(
  ActionTypes.GetAssetsFailure,
  props<{ payload: { error: any } }>()
);
export const setCombineAssetList = createAction(
  ActionTypes.SetCombineAssetList,
  props<{ payload: { combineAssetList: any[] } }>()
);
export const getTypeAssets = createAction(ActionTypes.GetTypeAssets);
export const getTypeAssetsSuccessfully = createAction(
  ActionTypes.GetTypeAssetsSuccessfully,
  props<{ payload: { assetType: any } }>()
);
export const getTypeAssetsFailure = createAction(
  ActionTypes.GetTypeAssetsFailure,
  props<{ payload: { error: any } }>()
);
