import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from '@auth0/auth0-angular';
import { OverviewContainerComponent } from './containers/overview-container/overview-container.component';

const routes: Routes = [
  {
    path: 'emissions',
    component: OverviewContainerComponent,
    children: [
      {
        path: '',
        redirectTo: 'asset',
        pathMatch: 'full'
      },
      {
        path: 'asset',
        loadComponent: () =>
          import('./containers/asset-container/asset-container.component').then(
            (m) => m.AssetContainerComponent
          ),
        data: { label: 'assetView', assetType: 'asset' },
        canActivate: [AuthGuard]
      },
      {
        path: 'event',
        loadComponent: () =>
          import(
            './containers/emissions-event-container/emissions-event-container.component'
          ).then((m) => m.EmissionsEventContainerComponent),
        data: { label: 'eventView', assetType: 'event' },
        canActivate: [AuthGuard]
      }
    ]
  },
  {
    path: '**',
    redirectTo: 'emissions',
    pathMatch: 'full'
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
  exports: [RouterModule]
})
export class AppRoutingModule {}
