/* angular */
import { createReducer, on } from '@ngrx/store';

/* actions */
import * as AssetsDataStoreActions from './assets-data-store.actions';
import {
  BulkAssetsImportStatuses,
  UpdateAssetsStatus,
  ImportBulkAssets
} from './assets-data-store.interface';
import { IAsset } from '@models/asset-model';

/* state key */
export const featureKey = 'assets';

/* state interface */

export interface AssetsDataStore {
  isLoading?: boolean;
  assets?: IAsset[];
  activeAsset?: IAsset;
  importAssets?: ImportBulkAssets;
  updateAssetsStatus?: UpdateAssetsStatus;
  combineAssetList?: any[];
  assetType?: any[];
}

/* initial state */
export const initialState: AssetsDataStore = {
  isLoading: null,
  assets: [],
  activeAsset: null,
  importAssets: null,
  updateAssetsStatus: null,
  combineAssetList: null,
  assetType: null
};

export const reducer = createReducer(
  initialState,
  on(AssetsDataStoreActions.bulkAssetsCSVDataFileSelected, (state, action) => ({
    ...state,
    importAssets: {
      ...state.importAssets,
      status: BulkAssetsImportStatuses.VALIDATING_FILE,
      company: {
        id: action.payload.companyId,
        divisions: action.payload.divisions
      },
      uploadedData: {
        ...state.importAssets?.uploadedData,
        csvFile: {
          name: action.payload.csvFile?.name,
          size: action.payload.csvFile?.size,
          type: action.payload.csvFile?.type
        }
      },
      importedData: {
        ...state.importAssets?.importedData,
        processedRowsCount: 0,
        successfullyImportedAssets: [],
        erroredAssets: []
      }
    }
  })),

  on(AssetsDataStoreActions.bulkAssetsCSVDataValidated, (state, action) => ({
    ...state,
    importAssets: {
      ...state.importAssets,
      status: BulkAssetsImportStatuses.PARSING_DATA,
      uploadedData: {
        ...state.importAssets?.uploadedData,
        recordRows: action.payload.recordRows
      }
    }
  })),

  on(AssetsDataStoreActions.bulkAssetsCSVParsingStarted, (state, action) => ({
    ...state,
    importAssets: {
      ...state.importAssets,
      status: BulkAssetsImportStatuses.PARSING_DATA
    }
  })),

  on(
    AssetsDataStoreActions.bulkAssetCSVFileForInvalidDataGenerated,
    (state, action) => ({
      ...state,
      importAssets: {
        ...state.importAssets,
        status: BulkAssetsImportStatuses.DATA_LOADED,
        uploadedData: {
          ...state.importAssets?.uploadedData,
          invalidDataCsvFile: action.payload.csvFile
        }
      }
    })
  ),

  on(AssetsDataStoreActions.bulkAssetsCSVRecordParsed, (state, action) => ({
    ...state,
    importAssets: {
      ...state.importAssets,
      uploadedData: {
        ...state.importAssets?.uploadedData,
        recordHashmap: action.payload.recordHashmap,
        headerArray: action.payload.headers
      }
    }
  })),
  on(AssetsDataStoreActions.importAssetSuccessful, (state, action) => {
    let count = state.importAssets.importedData.processedRowsCount;
    count++;
    return {
      ...state,
      importAssets: {
        ...state.importAssets,
        importedData: {
          ...state.importAssets?.importedData,
          processedRowsCount: count,
          successfullyImportedAssets: [
            ...state.importAssets.importedData.successfullyImportedAssets,
            { id: action.payload.assetId }
          ]
        }
      }
    };
  }),

  on(AssetsDataStoreActions.importAssetFailure, (state, action) => {
    return {
      ...state,
      importAssets: {
        ...state.importAssets,
        importedData: {
          ...state.importAssets?.importedData
        }
      }
    };
  }),
  on(AssetsDataStoreActions.importAsset, (state, action) => ({
    ...state,
    importAssets: {
      ...state.importAssets,
      status: BulkAssetsImportStatuses.IMPORTING_ASSETS
    }
  })),
  on(AssetsDataStoreActions.bulkAssetsImportCompleted, (state, action) => ({
    ...state,
    importAssets: {
      ...state.importAssets,
      status: BulkAssetsImportStatuses.IMPORTING_COMPLETED
    }
  })),
  on(AssetsDataStoreActions.resetImportAssets, (state, action) => ({
    ...state,
    importAssets: null
  })),
  on(AssetsDataStoreActions.saveError, (state, action) => {
    let count = state.importAssets.importedData.processedRowsCount;
    count++;
    let recordError =
      state.importAssets.uploadedData.recordHashmap[action.payload.index + 1];
    if (recordError) {
      const lackingColumnCount =
        state.importAssets.uploadedData.headerArray.length - recordError.length;
      if (lackingColumnCount > 0) {
        for (let i = 0; i < lackingColumnCount; i++) {
          recordError = [...recordError, ''];
        }
      }
      recordError = [...recordError, action.payload.message];
    }
    return {
      ...state,
      importAssets: {
        ...state.importAssets,
        importedData: {
          ...state.importAssets.importedData,
          processedRowsCount: count,
          erroredAssets: recordError
            ? [...state.importAssets.importedData.erroredAssets, recordError]
            : [...state.importAssets.importedData.erroredAssets]
        }
      }
    };
  }),

  on(AssetsDataStoreActions.resetAssetState, (state, action) => initialState),

  //#region Bulk Asset Activate/Deactivate Reducers
  on(AssetsDataStoreActions.bulkAssetStatusSelected, (state, action) => ({
    ...state,
    updateAssetsStatus: {
      ...state.updateAssetsStatus,
      successfullyUpdateStatusAssetIds: [],
      failureUpdateStatusAssets: [],
      processedAssetsCount: 0
    }
  })),
  on(AssetsDataStoreActions.updateAssetStatusFailure, (state, action) => {
    let count = state.updateAssetsStatus.processedAssetsCount;
    count++;
    return {
      ...state,
      updateAssetsStatus: {
        ...state.updateAssetsStatus,
        processedAssetsCount: count,
        failureUpdateStatusAssetIds: [
          ...state.updateAssetsStatus.failureUpdateStatusAssets,
          {
            assetId: action.payload.assetId,
            errorMessage: action.payload.errorMessage
          }
        ]
      }
    };
  }),
  on(AssetsDataStoreActions.updateAssetStatusSuccessful, (state, action) => {
    let count = state.updateAssetsStatus.processedAssetsCount;
    count++;
    return {
      ...state,
      updateAssetsStatus: {
        ...state.updateAssetsStatus,
        processedAssetsCount: count,
        successfullyUpdateStatusAssetIds: [
          ...state.updateAssetsStatus.successfullyUpdateStatusAssetIds,
          action.payload.assetId
        ]
      }
    };
  }),
  on(AssetsDataStoreActions.bulkAssetStatusReset, (state, action) => ({
    ...state,
    updateAssetsStatus: {
      ...state.updateAssetsStatus,
      successfullyUpdateStatusAssetIds: [],
      failureUpdateStatusAssets: [],
      processedAssetsCount: 0
    }
  })),

  on(AssetsDataStoreActions.getAssets, (state, action) => ({
    ...state,
    isLoading: true
  })),

  on(AssetsDataStoreActions.getAssetsSuccessfully, (state, action) => ({
    ...state,
    assets: action.payload.assetList,
    isLoading: false
  })),

  //TODO handle error
  on(AssetsDataStoreActions.getAssetsFailure, (state, action) => ({
    ...state,
    isLoading: false
  })),

  on(AssetsDataStoreActions.getTypeAssetsSuccessfully, (state, action) => ({
    ...state,
    assetType: action.payload.assetType
  })),

  //TODO handle error
  on(AssetsDataStoreActions.getTypeAssetsFailure, (state, action) => ({
    ...state
  })),

  on(AssetsDataStoreActions.setCombineAssetList, (state, action) => ({
    ...state,
    combineAssetList: action.payload.combineAssetList
  }))
);
