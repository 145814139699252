import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { fromEvent } from 'rxjs';
import { mergeMap, catchError, tap, switchMap } from 'rxjs/operators';

/* actions */
import * as AssetsDataStoreActions from './assets-data-store.actions';
import papa from 'papaparse';
import { BulkAssetImportService } from '@services/bulk-asset-import/bulk-asset-import.service';
import { IAsset } from '@models/asset-model';

@Injectable()
export class AssetsDataStoreEffects {
  newLineReg = /\r\n|\n/;
  createAssetError = 'Create Asset error';
  getCompanyAssetError = 'Get Company Asset error';
  updateAssetStatusError = 'Update Asset Status error';

  constructor(
    private actions$: Actions,
    public bulkAssetImportService: BulkAssetImportService
  ) {}

  validateBulkImportCSVFile$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AssetsDataStoreActions.bulkAssetsCSVDataFileSelected),
      mergeMap((action) => {
        /* parse and validate CSV file */
        const { companyId, divisions, fieldsCheckDup } = action.payload;
        const params = {
          companyId,
          divisions,
          fieldsCheckDup
        };
        return action.payload.csvFile
          ? [
              AssetsDataStoreActions.bulkAssetsCSVParsingStarted({
                payload: {
                  csvFile: action.payload.csvFile,
                  params
                }
              })
            ]
          : [];
      })
    )
  );

  parseCSV$ = createEffect(() => {
    let tmpPayload: any = {};
    return this.actions$.pipe(
      ofType(AssetsDataStoreActions.bulkAssetsCSVParsingStarted),
      tap((action) => (tmpPayload = action.payload)),
      switchMap((action: any) => {
        const reader = new FileReader();
        reader.readAsText(action.payload.csvFile);
        return fromEvent(reader, 'loadend');
      }),
      mergeMap((fileReader: any) => {
        /* parse and validate CSV file */
        return this.bulkAssetImportService
          .getData(
            this.bulkAssetImportService.getFilePathDownloadAsset(),
            null,
            { responseType: 'text' }
          )
          .pipe(
            mergeMap((fileData) => {
              const headerSampleFile = papa
                .parse(fileData)
                ?.data[0]?.map((item) => item.trim());
              const resultRecords = [];
              const recordHashmap = {};
              const csvData = fileReader.target.result;
              const csvRecordsArray = papa.parse(csvData);
              const headers = csvRecordsArray?.data[0]?.map((item) =>
                item.trim()
              );
              // this.bulkAssetImportService.csvContainsRequiredColumns(headerSampleFile)
              if (
                !this.bulkAssetImportService.csvContainsRequiredColumns(
                  headerSampleFile
                )
              ) {
                return [
                  AssetsDataStoreActions.bulkAssetsCSVRecordParsed({
                    payload: {
                      resultRecords: null,
                      headers: null,
                      recordHashmap,
                      params: tmpPayload.params
                    }
                  })
                ];
              }
              let emptyRow = 0;

              for (
                let record = 1;
                record < csvRecordsArray?.data?.length;
                record++
              ) {
                const recordData = csvRecordsArray?.data[record];
                if (recordData[0] === '' && recordData?.length === 1) {
                  emptyRow++;
                } else {
                  recordHashmap[record - emptyRow] = recordData;
                  const recordDataObject = {};
                  for (let index = 0; index < headers?.length; index++) {
                    recordDataObject[
                      this.bulkAssetImportService.toCamelCaseString(
                        headers[index]?.toLowerCase()
                      )
                    ] = recordData[index]?.trim();
                  }
                  resultRecords.push(recordDataObject);
                }
              }
              return [
                AssetsDataStoreActions.bulkAssetsCSVRecordParsed({
                  payload: {
                    resultRecords,
                    headers:
                      this.bulkAssetImportService.toCamelCaseArray(headers),
                    recordHashmap,
                    params: tmpPayload.params
                  }
                })
              ];
            })
          );
      })
    );
  });

  parsingRecordCSV$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AssetsDataStoreActions.bulkAssetsCSVRecordParsed),
      mergeMap((action) => {
        let recordParsed = [];
        if (action.payload.resultRecords) {
          action.payload.resultRecords.forEach((record) => {
            recordParsed.push(
              this.bulkAssetImportService.parseRecord(
                record,
                action.payload.params
              )
            );
          });
        } else {
          recordParsed = null;
        }
        return [
          AssetsDataStoreActions.bulkAssetsValidateDuplicate({
            payload: {
              recordParsed,
              fieldsCheckDup: action.payload.params.fieldsCheckDup
            }
          })
        ];
      })
    )
  );

  validateBulkImportCSVData$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AssetsDataStoreActions.bulkAssetsValidateDuplicate),
      mergeMap((action) => {
        // Add DeDup field to recordParsed
        const { recordParsed, fieldsCheckDup } = action.payload;
        const recordRows = this.bulkAssetImportService.deDupRecord(
          recordParsed,
          fieldsCheckDup
        );
        return [
          AssetsDataStoreActions.bulkAssetsCSVDataValidated({
            payload: { recordRows }
          })
        ];
      })
    )
  );
  bulkAssetCSVDataUpload$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AssetsDataStoreActions.bulkAssetCSVDataUpload),
      mergeMap((action) => {
        const { record, index, headerArray, prePopulateData, companyId } =
          action.payload;
        if (!record.hasOwnProperty('fieldDup') || !record.fieldDup.length) {
          if (
            this.bulkAssetImportService.getMisMatchColumnAmount(
              record,
              headerArray
            ) < 0
          ) {
            // Save error row to ErrorList
            return [
              AssetsDataStoreActions.saveError({
                payload: {
                  index,
                  message:
                    this.bulkAssetImportService?.errorMessagesTranslated
                      ?.misMatchingColumnAmount
                }
              })
            ];
          }

          const validatedRecord = this.bulkAssetImportService.validateRecord(
            record,
            prePopulateData
          );
          if (!validatedRecord.isValid) {
            // Save error row to ErrorList
            return [
              AssetsDataStoreActions.saveError({
                payload: {
                  index,
                  message: `${validatedRecord.fieldName} ${this.bulkAssetImportService?.errorMessagesTranslated?.validateErrorMessage}`
                }
              })
            ];
          } else {
            return [
              AssetsDataStoreActions.importAsset({
                payload: {
                  assetParams: validatedRecord.data,
                  companyId: action.payload.companyId,
                  index: action.payload.index
                }
              })
            ];
          }
        } else {
          // Save error row to ErrorList
          return [
            AssetsDataStoreActions.saveError({
              payload: {
                index,
                message: `${this.bulkAssetImportService?.errorMessagesTranslated
                  ?.csvDuplicateMessage} ${this.bulkAssetImportService.toTitleCase(
                  record.fieldDup?.join(' & ')
                )}`
              }
            })
          ];
        }
      })
    )
  );

  importAsset$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AssetsDataStoreActions.importAsset),
      mergeMap((action) => {
        const { assetParams } = action.payload;
        return this.bulkAssetImportService.createRecord(assetParams).pipe(
          mergeMap((res: any) => {
            if (res?.body?.[0]?.error) {
              return [
                AssetsDataStoreActions.saveError({
                  payload: {
                    index: action.payload.index,
                    message: res?.body?.[0]?.error
                  }
                })
              ];
            } else {
              return [
                AssetsDataStoreActions.importAssetSuccessful({
                  payload: {
                    assetId: res?.body?.[0]?.assetId
                  }
                })
              ];
            }
          }),
          catchError((error) => {
            // Save error row to ErrorList
            return [
              AssetsDataStoreActions.saveError({
                payload: {
                  index: action.payload.index,
                  message: error?.error?.message
                    ? error.error.message
                    : this.createAssetError
                }
              })
            ];
          })
        );
      })
    )
  );

  /*//#region Bulk Asset Activate/Deactivate Effects
  updateAssetStatus$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AssetsDataStoreActions.updateAssetStatus),
      mergeMap((action) => {
        const { assetId, assetStatus } = action.payload;
        return this.coreAssetApiService.updateAsset(assetId, { status: assetStatus ? AssetStatus.ACTIVE : AssetStatus.INACTIVE }).pipe(
          mergeMap((res: any) => {
            return [AssetsDataStoreActions.updateAssetStatusSuccessful({
              payload: {
                assetId: assetId
              }
            })];
          }),
          catchError(error => {
            return [AssetsDataStoreActions.updateAssetStatusFailure({
              payload: {
                assetId: assetId,
                errorMessage: error?.error?.message ? error.error.message : this.updateAssetStatusError
              }
            })];
          })
        );
      })
    )
  );*/

  getAssets$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AssetsDataStoreActions.getAssets),
      mergeMap((action) => {
        const { companyId, params } = action.payload;
        return this.bulkAssetImportService.getAssets(companyId, params).pipe(
          mergeMap((assets: IAsset[]) => {
            return [
              AssetsDataStoreActions.getAssetsSuccessfully({
                payload: { assetList: assets }
              })
            ];
          }),
          catchError((error) => {
            return [
              AssetsDataStoreActions.getAssetsFailure({
                payload: { error }
              })
            ];
          })
        );
      })
    )
  );

  /*getTypeAssets$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AssetsDataStoreActions.getTypeAssets),
      mergeMap((action) => {
        return this.coreAssetApiService.getTypeAssets().pipe(
          mergeMap((res: any) => {
            const assetType = res.body;
            return [AssetsDataStoreActions.getTypeAssetsSuccessfully({
              payload: { assetType }
            })];
          }),
          catchError(error => {
            return [AssetsDataStoreActions.getTypeAssetsFailure({
              payload: { error }
            })];
          })
        );
      })
    )
  );*/
}
